import React, { Fragment, useEffect, useReducer } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Lot5RegistrationForm from './components/Lot5RegistrationForm';

import {reducer, initialState} from './AppState';
import SonyHeader from './components/SonyHeader';

import t from './Translations'
import SonyFooter from './components/SonyFooter';

const supportedLocales = 
    ["en_GB","bg_BG","el_GR","cs_CZ","da_DK","de_AT","de_CH","de_DE","de_LU","el_GR","en_IE","es_ES",
    "et_EE","fi_FI","fr_BE","fr_CH","fr_FR","fr_LU","hr_HR","hu_HU","it_IT","lt_LT","lv_LV",
    "nl_BE","nl_NL","no_NO","pl_PL","pt_PT","ro_RO","sk_SK","sl_SI","sv_SE","tr_TR","en_MT",
    "en_IS","de_DE"];

const Lot5RegistrationScreen= () => {
    const { locale="en_GB" } = useParams();
    const validRequest=locale.length===5 && locale.charAt(2)==='_' && supportedLocales.includes(locale);
    const lang=validRequest?locale.substring(0,2):"en";
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!state.registrationId ) {
            axios.get("/init").then(resp => {
                dispatch({type: "registrationId", value: resp.data.requestId});
                dispatch({type: "captcha.image", value: resp.data.captchaImage});
                //console.log("got:", resp.data)
            });
        }
      }, [state.registrationId]);

    useEffect(() => {
        dispatch({type: "language", value: lang});
    }, [lang]);
    

    const validateCaptcha=() => {
        if (state.registrationId ) {
            dispatch({type: "captcha.validating", value: true});
            dispatch({type: "captcha.message", value: null});
            const id=encodeURIComponent(state.registrationId);
            const val=encodeURIComponent(state.captcha.value);
            axios.get(`/validate_captcha?requestId=${id}&captchaValue=${val}`)
            .then(resp => {
                dispatch({type: "captcha.validating", value: false});
                if (resp.data.captchaOk) {
                    dispatch({type: "captcha.validationOk", value: resp.data.captchaOk});
                } else {
                    dispatch({type: "captcha.value", value: ""});
                    dispatch({type: "captcha.message", value: t(lang, "captchaNotOk")});
                    if (resp.data.requestId) {
                        dispatch({type: "registrationId", value: resp.data.requestId});
                    }
                    if (resp.data.captchaImage) {
                        dispatch({type: "captcha.image", value: resp.data.captchaImage});
                    }
                }
            });
        }
    }
    const submit=() => {
        if (!state.registrationId || !state.captcha.validationOk) return;
        const {request} = state;
        let allok=true;
        dispatch({type: "validationMessage.clear"});
        
        if (request.model.trim().length===0) {
            allok=false;
            dispatch({type: "validationMessage.model", value: t(lang, "emptyModelError")});
        }
        if (request.name.trim().length===0) {
            allok=false;
            dispatch({type: "validationMessage.name", value: t(lang, "emptyLastNameError")});
        }
        if (request.firstName.trim().length===0) {
            allok=false;
            dispatch({type: "validationMessage.firstName", value: t(lang, "emptyFirstNameError")});
        }
        if (request.email.trim().length===0) {
            allok=false;
            dispatch({type: "validationMessage.email", value: t(lang, "emptyEmailError")});
        } else if (!isValidEmailAddress(request.email)) {
            allok=false;
            dispatch({type: "validationMessage.email", value: t(lang, "invalidEmailError")});
        }
        
        if (request.companyName.trim().length===0) {
            allok=false;
            dispatch({type: "validationMessage.companyName", value: t(lang, "emptyCompanyNameError")});
        }
        if (request.vat.trim().length===0) {
            allok=false;
            dispatch({type: "validationMessage.vat", value: t(lang, "emptyVatError")});
        }
        if (!allok) return;
        const id=encodeURIComponent(state.registrationId);
        const val=encodeURIComponent(state.captcha.value);
        const data = {
            ...request, fileName: state.fileUpload.fileName, fileExtension: state.fileUpload.fileNameExtension
        }
        dispatch({type:'setSubmitting', value: true});
        axios.post(`/submit?requestId=${id}&captchaValue=${val}`, data)
        .then(resp => {
            dispatch({type:'submitInfo', value: {
                submitting: false,
                submitted: true,
                submitOk: resp.data.submitOk,
                submitError: !resp.data.submitOk,
                submitErrorMessage: resp.data.message,
            }});
        }).catch(err => {
            dispatch({type: 'submitInfo', value:{
                submitting: false,
                submitted: true,
                submitOk: false,
                submitError: true,
                submitErrorMessage: err,
            }});
            console.log("Submit error", err);

        });

        
    }
    if (!validRequest) {
        return (
            <React.Fragment>
                <SonyHeader locale="en_GB" />
                <div style={rootStyle}>
                    <div style={infoStyle}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        INVALID request
                    </div>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                </div>
                <SonyFooter locale="en_GB" />
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
        <SonyHeader locale={locale} />
        <div style={mainTitleContainerStyle}>
            <div style={titleStyle}>{t(lang, "title")}</div>
        </div>
        <div style={rootStyle}>
        {!state.submitInfo.submitted?(
            <React.Fragment>
            <div style={infoStyle}> 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {t(lang, "infoLine1")}<br />
                {t(lang, "infoLine2")}<br />
                {t(lang, "infoLine3")}<br />
                
            </div>
            <Lot5RegistrationForm 
                state={state} 
                dispatch={dispatch}
                validateCaptcha={validateCaptcha}
                submit={() => submit()}
                readOnly={state.submitInfo.submitting}
                />
            </React.Fragment>
        ):(
            <Fragment>
            {state.submitInfo.submitOk?(
                <Fragment>
                    <div style={infoStyle}>     
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {t(lang, "registrationCompleteInfoLine1")}<br />
                        {t(lang, "registrationCompleteInfoLine2")}<br />
                    </div>
                    <br /><br /><br /><br /><br /><br />
                </Fragment>
            ):(
                <Fragment>
                    <div style={{...infoStyle, color: "red"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {t(lang, "generalErrorLine1")}<br />
                    {t(lang, "generalErrorLine2")}<br />
                    </div>
                    <br /><br /><br /><br /><br /><br />
                </Fragment>
            )}
            </Fragment>
        )}
        </div>
        <div style={{height: "4rem"}}></div>
        <SonyFooter locale={locale} />
        </React.Fragment>
    );

}

export default Lot5RegistrationScreen;


const emailValidationPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const isValidEmailAddress = (mail) => {
    return emailValidationPattern.test(mail);
}

const mainTitleContainerStyle = {
    backgroundColor: "#516b86",
    color: "#000000",
    width: "100%",
    display:"flex",
    alignItems: "center",
    justifyContent: "center",
    height: 162,
}

const titleStyle= {
    color: "white",
    fontFamily: "SST W01 Roman, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 40,
    backgroundColor: "#516b86",
    width: "60%",
    paddingLeft: "10px",
}

const rootStyle = {
    display:"flex",
    justifyContent:"center",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    fontFamily: "SST W01 Roman, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 16,
    alignItems: "center",
};

const infoStyle = {
    backgroundColor: "#FDF6E5",
    color: "#2f353d",
    width: "60%",
    padding: "15px",
    fontWeigt: 400,
    lineHeight: 1.65,
    background: `#fdf6e5 url(${process.env.PUBLIC_URL}/warning.png) no-repeat 17px 17px`,
}
