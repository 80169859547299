import React, { useState } from 'react';
import axios from "axios";
import Autosuggest from 'react-autosuggest';
import AlertMessage from './AlertMessage';
import t from '../Translations'
/*

https://github.com/moroshko/react-autosuggest#installation
https://codepen.io/moroshko/pen/EPZpev

*/

const ModelSelection = ({lang, modelName, onChange, alertMessage, readOnly}) => {

    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

const setOnChange=(event, { newValue }) => {onChange(newValue)};

const inputProps = {
    placeholder: t(lang, "modelSelectionIntructions"),
    value: modelName,
    autoFocus: true,
    tabIndex: "1",
    onChange: setOnChange,
    ariaLabel: t(lang, "modelName"),
};
  

return (
  <div>
    {readOnly?(
      <div style={inputItem}>
        <input value={modelName} readOnly={true} style={textInputStyle} ariaLabel={t(lang, "modelName")}/>
      </div>
    ):(
      <div style={inputItem}>
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={({value}) => onSuggestionsFetchRequested(value, setLoading, setSuggestions)}
          onSuggestionsClearRequested={() => {setSuggestions([])}}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps} />
          {loading && (<span style={{paddingLeft: 15}}><img src={`${process.env.PUBLIC_URL}/indicator_wheel1.gif`} alt="loading"/></span>)}
      </div>
    )}
    {alertMessage && (<AlertMessage message={alertMessage} />)}
  </div>

)
};

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <div>
      {suggestion.name}
    </div>
  );


const onSuggestionsFetchRequested = (value, setLoading, setSuggestions) => {
  setLoading(true);
  const urlValue=encodeURIComponent(value);
  axios.get(`/model?max=8&channel=CONS&name=${urlValue}`).then(resp => {
    setSuggestions(resp.data);
    setLoading(false);
  }).catch(err => {
    console.log("Model suggestion error", err);
    setLoading(false);
  })
};


const inputItem = {
  display:"flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  width: 400,
}

const textInputStyle= {
  width: "100%",
  height: 35,
  padding: "5px 10px",
  border: "1px solid #aaa",
  borderRadius: 4,
}

export default ModelSelection;