
export const initialState = {
    registrationId: '',
    captcha: {
        image: null,
        message: null,
        value: "",
        validating: false,
        validationOk: false,
    },
    request: {model: "", name: "", firstName: "", email: "", companyName: "", vat: ""},
    validationMessage: {model: null, name: null, firstName: null, email: null, companyName: null, vat: null},
    fileUpload: {
        uploading: false,
        message: null,
        uploadingComplete: false,
        fileName: null,
        fileNameExtension: null,
    },
    submitInfo: {
        submitting: false,
        submitted: false,
        submitOk: false,
        submitError: false,
        submitErrorMessage: null,
    }
};

export const reducer = (state, action) => {
    //const prevState=state;
    const newState=actualReducer(state, action);
    //console.log("Reducer - Previous State:", prevState);
    //console.log("Reducer - Action: "+action.type, action);
    //console.log("Reducer - New state", newState);
    return newState;
}

const actualReducer = (state, action) => {
    if (action.type.startsWith("captcha.")) {
        const captchaItem=action.type.substring(8);
        const captcha= {...state.captcha, [captchaItem]: action.value};
        return {...state, captcha};
    }
    if (action.type.startsWith("request.")) {
        const dataItem=action.type.substring(8);
        const request= {...state.request, [dataItem]: action.value};
        return {...state, request};
    }
    if (action.type === "validationMessage.clear") {
        const validationMessage= {model: null, name: null, firstName: null, email: null, companyName: null, vat: null};
        return {...state, validationMessage};
    }
    if (action.type.startsWith("validationMessage.")) {
        const dataItem=action.type.substring(18);
        const validationMessage= {...state.validationMessage, [dataItem]: action.value};
        return {...state, validationMessage};
    }
    if (action.type.startsWith("fileUpload.")) {
        const dataItem=action.type.substring(11);
        const fileUpload= {...state.fileUpload, [dataItem]: action.value};
        return {...state, fileUpload};
    }
    switch (action.type) {
        case 'language': return {...state, language: action.value}
        case 'registrationId':
            return {...state, registrationId: action.value};
        case 'setSubmitting':
            return {...state, submitInfo: {...state.submitInfo, submitting: action.value}};
        case 'submitInfo':
            return {...state, submitInfo: action.value};
        default:
            throw new Error("Unsupported action type: "+action.type);
    }
  }

  
