
import t from '../Translations'

const SonyFooter = ({locale, language}) => {
const lang=encodeURI(locale.length===5&&locale.charAt(2)==='_'?locale.substring(0,2):"en");
const country=encodeURI(locale.length===5&&locale.charAt(2)==='_'?locale.substring(3).toUpperCase():"GB");
const tacURL="https://campaign.odw.sony-europe.com/dynamic/terms-conditions/index.jsp?country="+country+"&language="+lang;
const privacyURL="https://www.sony.co.uk/eu/pages/privacy/"+lang+"_"+country+"/privacy_overview.html"
    return (
    <div style={sonyFooterStyle}>
        <div style={sonyFooterContainer}>
            <div style={copyrightStyle}>© 2021 SONY EUROPE B.V.<br />
            <a style={footLinkStyle} href={tacURL}>
                {t(lang, "tac")}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a style={footLinkStyle} href={privacyURL}>
                {t(lang, "privacy_policy")}
            </a>
            </div>
        </div>
    </div>
)};

const sonyFooterStyle= {
    display:"flex",
    justifyContent:"center",
    flexDirection: "column",
    alignItems: "center",
    background: "rgb(31, 32, 36)",
    height: 123,
    margin: 0,
    padding: 0,
}

const copyrightStyle = {
    fontFamily: "SST W01 Roman, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 10,
    fontWeight: 400,
    color: "#878792",
    lineHeight: "15px",
};

const footLinkStyle = {
    fontFamily: "SST W01 Roman, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 10,
    lineHeight: "15px",
    color: "#e4e7eb",
    fontStyle: "normal",
    textDecoration: "none",
    textSizeAdjust: "100%",
    textTransform: "uppercase",
    vericalAlign: "baseline",
};

const sonyFooterContainer = {
    width: "60%",
    paddingLeft: "30px",
    align: "left",
}

export default SonyFooter;