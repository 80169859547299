const translations = {
    en: {	
        title: "Repair information for professional repairers",
        infoLine1: "Professional repairers can request access to repair information of electronic displays, according to the prescriptions and conditions set in eco-design regulation (EU) 2019/2021.",
        infoLine2: "Repair information can be provided for display models after a period of two years from their first introduction into the market.",
        infoLine3: "To request access to the repair information, please provide the following information:",
        modelName: "Model Name",
        modelSelectionIntructions: "Type a model name",
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email Address",
        companyName: "Company Name",
        vat: "Official registration number",
        vatInfo: "e.g. VAT number, registration/certification number as repairer",
        captchaInstructions: "Type the characters you see in the picture",
        validateCaptcha: "Validate",
        captchaNotOk: "You entered a wrong value. Please try again.",
        uploadFile: "Upload file",
        submitToSony: "Submit to Sony",
        emptyModelError: "Please select the model.",
        emptyLastNameError: "Please fill out your name.",
        emptyFirstNameError: "Please fill out your first name.",
        emptyEmailError: "Please fill out your email addres.",
        invalidEmailError: "This email address is invalid.",
        emptyCompanyNameError: "Please fill out the Company Name.",
        emptyVatError: "Please fill out your official registration number.",
        uploadInfo: "Please upload evidence of the company’s liability insurance* (policy, certificate, etc.)",
        fileUploadSizeError: "The maximum file size is 10MB. This file is too big.",
        fileUploadTypeError: "This file type is not supported.",
        registrationCompleteInfoLine1: "Thank you for your request for access to the repair information.",
        registrationCompleteInfoLine2: "We are currently reviewing the information provided and we aim at completing this review within 5 working days.",
        generalErrorLine1: "An error occurred",
        generalErrorLine2: "Please try again later.",
        tac: "TERMS AND CONDITIONS OF WEBSITE USE",
        privacy_policy: "PRIVACY POLICY",
    },	
    fr: {	
        title: "Informations de réparation pour les réparateurs professionnels",
        infoLine1: "Les réparateurs professionnels peuvent demander l'accès aux informations de réparation des écrans électroniques, conformément aux prescriptions et aux conditions établies dans la réglementation sur l'écoconception (UE) 2019/2021.",
        infoLine2: "Les réparateurs professionnels peuvent également demander l'accès aux informations de réparation d'autres produits Sony Electronics.",
        infoLine3: "Pour demander l'accès aux informations de réparation, veuillez fournir les informations suivantes :",
        modelName: "Nom du modèle",
        modelSelectionIntructions: "Saisissez un nom de modèle",
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse e-mail",
        companyName: "Nom de la société",
        vat: "Numéro d'identification officiel",
        vatInfo: "Par exemple, numéro de TVA, numéro d'immatriculation/de certification en tant que réparateur",
        captchaInstructions: "Saisissez les caractères figurant sur l'image",
        validateCaptcha: "Valider",
        captchaNotOk: "Vous avez saisi une valeur incorrecte. Veuillez réessayer.",
        uploadFile: "Charger un fichier",
        submitToSony: "Envoyer à Sony",
        emptyModelError: "Veuillez sélectionner le modèle.",
        emptyLastNameError: "Veuillez indiquer votre nom.",
        emptyFirstNameError: "Veuillez indiquer votre prénom.",
        emptyEmailError: "Veuillez indiquer votre adresse e-mail.",
        invalidEmailError: "Cette adresse e-mail n'est pas valide.",
        emptyCompanyNameError: "Veuillez indiquer le nom de la société.",
        emptyVatError: "Veuillez indiquer votre numéro d'identification officiel.",
        uploadInfo: "Veuillez charger le justificatif d'assurance responsabilité de la société* (police, certificat, etc.)",
        fileUploadSizeError: "La taille de fichier maximale est de 10 Mo. Ce fichier est trop volumineux.",
        fileUploadTypeError: "Ce type de fichier n'est pas pris en charge.",
        registrationCompleteInfoLine1: "Nous vous remercions de votre demande d'accès aux informations de réparation.",
        registrationCompleteInfoLine2: "Nous examinons actuellement les informations fournies et nous devrions terminer cet examen dans les 5 jours ouvrables.",
        generalErrorLine1: "Une erreur s'est produite",
        generalErrorLine2: "Veuillez réessayer plus tard.",
        tac: "CONDITIONS D'UTILISATION",
        privacy_policy: "POLITIQUE DE CONFIDENTIALITE",
    },	
    nl: {	
        title: "Reparatie-informatie voor professionele reparateurs",
        infoLine1: "Professionele reparateurs kunnen toegang vragen tot reparatie-informatie van elektronische displays, volgens de voorschriften en voorwaarden die zijn vastgelegd in de richtlijnen voor eco-design (EU) 2019/2021.",
        infoLine2: "Reparatie-informatie voor displaymodellen kan worden verstrekt na een periode van twee jaar vanaf de eerste introductie op de markt.",
        infoLine3: "Om toegang tot de reparatie-informatie te vragen, dient u de volgende informatie te verstrekken:",
        modelName: "Modelnaam",
        modelSelectionIntructions: "Typ een modelnaam",
        firstName: "Voornaam",
        lastName: "Achternaam",
        email: "Email Address",
        companyName: "Bedrijfsnaam",
        vat: "Officieel registratienummer",
        vatInfo: "bijv. btw-nummer, registratie-/certificeringsnummer als reparateur",
        captchaInstructions: "Typ de tekens die u in de afbeelding ziet",
        validateCaptcha: "Valideren",
        captchaNotOk: "U hebt een verkeerde waarde ingevoerd. Probeer het opnieuw.",
        uploadFile: "Bestand uploaden",
        submitToSony: "Verzenden naar Sony",
        emptyModelError: "Selecteer het model.",
        emptyLastNameError: "Vul uw achternaam in.",
        emptyFirstNameError: "Vul uw voornaam in.",
        emptyEmailError: "Vul uw e-mailadres in.",
        invalidEmailError: "Dit e-mailadres is ongeldig.",
        emptyCompanyNameError: "Vul de bedrijfsnaam in.",
        emptyVatError: "Vul uw officiële registratienummer in.",
        uploadInfo: "Upload bewijs van de aansprakelijkheidsverzekering van het bedrijf* (polis, certificaat, etc.)",
        fileUploadSizeError: "De maximale bestandsgrootte is 10 MB. Dit bestand is te groot.",
        fileUploadTypeError: "Dit bestandstype wordt niet ondersteund.",
        registrationCompleteInfoLine1: "Hartelijk dank voor uw aanvraag voor toegang tot de reparatie-informatie.",
        registrationCompleteInfoLine2: "We bekijken momenteel de verstrekte informatie. We streven ernaar deze beoordeling binnen 5 werkdagen te voltooien.",
        generalErrorLine1: "Er is een fout opgetreden",
        generalErrorLine2: "Probeer het later nog eens.",
        tac: "VOORWAARDEN GEBRUIK WEBSITE",
        privacy_policy: "PRIVACYBELEID",
    },	
    de: {	
        title: "Reparaturinformationen für professionelle Werkstätten",
        infoLine1: "Professionelle Werkstätten können Zugriff auf Reparaturinformationen von elektronischen Displays gemäß den Vorschriften und Bedingungen der Ökodesign-Richtlinie (EU) 2019/2021 beantragen.",
        infoLine2: "Reparaturinformationen für Displaymodelle können zwei Jahre nach ihrer ersten Markteinführung zur Verfügung gestellt werden.",
        infoLine3: "Um Zugriff auf die Reparaturinformationen zu erhalten, geben Sie bitte die folgenden Informationen an:",
        modelName: "Modellname",
        modelSelectionIntructions: "Geben Sie einen Modellnamen ein",
        firstName: "Vorname",
        lastName: "Nachname",
        email: "E-Mail-Adresse",
        companyName: "Firmenname",
        vat: "Amtliche Registrierungsnummer",
        vatInfo: "Beispielsweise Umsatzsteuer-Identifikationsnummer, Registrierungs-/Zertifizierungsnummer als Werkstatt",
        captchaInstructions: "Geben Sie die Zeichen ein, die Sie im Bild sehen",
        validateCaptcha: "Überprüfen",
        captchaNotOk: "Sie haben einen falschen Wert eingegeben. Versuchen Sie es erneut.",
        uploadFile: "Datei hochladen",
        submitToSony: "Bei Sony einreichen",
        emptyModelError: "Wählen Sie das Modell aus.",
        emptyLastNameError: "Bitte geben Sie Ihren Namen ein.",
        emptyFirstNameError: "Bitte geben Sie Ihren Vornamen ein.",
        emptyEmailError: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
        invalidEmailError: "Diese E-Mail-Adresse ist ungültig.",
        emptyCompanyNameError: "Bitte geben Sie den Firmennamen ein.",
        emptyVatError: "Bitte geben Sie Ihre amtliche Registrierungsnummer ein.",
        uploadInfo: "Bitte laden Sie einen Nachweis zu Ihrer Firmenhaftpflichtversicherung* hoch (Police, Zertifikat usw.)",
        fileUploadSizeError: "Die maximale Dateigröße beträgt 10 MB. Diese Datei ist zu groß.",
        fileUploadTypeError: "Dieser Dateityp wird nicht unterstützt.",
        registrationCompleteInfoLine1: "Vielen Dank für Ihren Antrag auf Zugriff auf Reparaturinformationen.",
        registrationCompleteInfoLine2: "Wir prüfen nun die bereitgestellten Informationen. Diese Überprüfung wird voraussichtlich innerhalb von 5 Arbeitstagen abgeschlossen.",
        generalErrorLine1: "Es ist ein Fehler aufgetreten",
        generalErrorLine2: "Versuchen Sie es zu einem späteren Zeitpunkt erneut.",
        tac: "NUTZUNGSBEDINGUNGEN FÜR DIE WEBSITE",
        privacy_policy: "DATENSCHUTZBESTIMMUNGEN",
    },	
    hr: {	
        title: "Informacije o popravku za profesionalne servisere",
        infoLine1: "Profesionalni serviseri mogu zatražiti pristup informacijama o popravku elektroničkih zaslona u skladu s propisima i uvjetima navedenima u Uredbi o utvrđivanju zahtjeva za ekološki dizajn (EU) 2019./2021.",
        infoLine2: "Informacije o popravku za modele zaslona možete dobiti nakon razdoblja od dvije godine od njihovog prvog uvođenja na tržište.",
        infoLine3: "Za pristup informacijama o popravku unesite navedene podatke:",
        modelName: "Naziv modela",
        modelSelectionIntructions: "Upišite naziv modela",
        firstName: "Ime",
        lastName: "Prezime",
        email: "Email Address",
        companyName: "Naziv tvrtke",
        vat: "Službeni registracijski broj",
        vatInfo: "npr. fiskalni broj, registracijski/certifikacijski broj servisera",
        captchaInstructions: "Upišite znakove koje vidite na slici",
        validateCaptcha: "Provjeri",
        captchaNotOk: "Unijeli ste pogrešnu vrijednost. Pokušajte ponovno.",
        uploadFile: "Učitavanje datoteke",
        submitToSony: "Pošaljite tvrtki Sony",
        emptyModelError: "Odaberite model.",
        emptyLastNameError: "Unesite prezime.",
        emptyFirstNameError: "Unesite ime.",
        emptyEmailError: "Unesite adresu e-pošte.",
        invalidEmailError: "Adresa e-pošte nije valjana.",
        emptyCompanyNameError: "Unesite naziv tvrtke.",
        emptyVatError: "Unesite službeni registarski broj.",
        uploadInfo: "Učitajte dokaz o osiguranju od odgovornosti tvrtke* (polica, potvrda, itd.)",
        fileUploadSizeError: "Maksimalna je veličina datoteke 10 MB. Datoteka je prevelika.",
        fileUploadTypeError: "Vrsta datoteke nije podržana.",
        registrationCompleteInfoLine1: "Hvala što ste podnijeli zahtjev za pristup informacijama o popravku.",
        registrationCompleteInfoLine2: "Trenutno pregledavamo vaše podatke i cilj nam je završiti provjeru u roku od pet radnih dana.",
        generalErrorLine1: "Došlo je do pogreške",
        generalErrorLine2: "Pokušajte ponovo kasnije.",
        tac: "ODREDBE I UVJ. UPOTR. WEB-MJ.",
        privacy_policy: "PRAVILA PRIVATNOSTI",
    },	
    el: {	
        title: "Πληροφορίες επισκευής για επαγγελματίες",
        infoLine1: "Οι επαγγελματίες επισκευαστές μπορούν να ζητήσουν πρόσβαση σε πληροφορίες επισκευής ηλεκτρονικών οθονών, σύμφωνα με τις προδιαγραφές και τις προϋποθέσεις που ορίζονται στον Κανονισμό για τον Οικολογικό Σχεδιασμό (ΕΕ) 2019/2021.",
        infoLine2: "Μπορούν να δοθούν πληροφορίες επισκευής για μοντέλα οθονών μετά από περίοδο δύο ετών από την πρώτη κυκλοφορία της οθόνης στην αγορά.",
        infoLine3: "Για να ζητήσετε πρόσβαση στις πληροφορίες επισκευής, θα πρέπει να παράσχετε τις παρακάτω πληροφορίες:",
        modelName: "Όνομα μοντέλου",
        modelSelectionIntructions: "Πληκτρολογήστε όνομα μοντέλου",
        firstName: "Όνομα",
        lastName: "Επώνυμο",
        email: "Διεύθυνση email",
        companyName: "Επωνυμία εταιρείας",
        vat: "Επίσημος αριθμός μητρώου",
        vatInfo: "π.χ. ΑΦΜ, αριθμός μητρώου/πιστοποίησης επισκευαστή",
        captchaInstructions: "Πληκτρολογήστε τους χαρακτήρες που βλέπετε στην εικόνα",
        validateCaptcha: "Επικύρωση",
        captchaNotOk: "Πληκτρολογήσετε μια λανθασμένη τιμή. Δοκιμάστε ξανά.",
        uploadFile: "Μεταφόρτωση αρχείου",
        submitToSony: "Υποβολή στη Sony",
        emptyModelError: "Επιλέξτε το μοντέλο.",
        emptyLastNameError: "Συμπληρώστε το όνομά σας.",
        emptyFirstNameError: "Συμπληρώστε το μικρό όνομα.",
        emptyEmailError: "Συμπληρώστε τη διεύθυνση email σας.",
        invalidEmailError: "Αυτή η διεύθυνση email δεν είναι έγκυρη.",
        emptyCompanyNameError: "Συμπληρώστε την επωνυμία της εταιρείας.",
        emptyVatError: "Συμπληρώστε τον επίσημο αριθμό μητρώου σας.",
        uploadInfo: "Ανεβάστε αποδεικτικό ασφάλισης της εταιρείας* (ασφαλιστήριο συμβόλαιο, πιστοποιητικό κ.λπ.)",
        fileUploadSizeError: "Το μέγιστο μέγεθος αρχείου είναι 10MB. Αυτό το αρχείο είναι πολύ μεγάλο.",
        fileUploadTypeError: "Αυτός ο τύπος αρχείου δεν υποστηρίζεται.",
        registrationCompleteInfoLine1: "Σας ευχαριστούμε για το αίτημά σας για πρόσβαση στις πληροφορίες επισκευής.",
        registrationCompleteInfoLine2: "Εξετάζουμε τις πληροφορίες που μας υποβάλατε και στοχεύουμε στην ολοκλήρωση αυτής της αξιολόγησης εντός 5 εργάσιμων ημερών.",
        generalErrorLine1: "Προέκυψε σφάλμα",
        generalErrorLine2: "Παρακαλούμε δοκιμάστε αργότερα.",
        tac: "ΧΡΗΣΗ ΔΙΑΔΙΚΤΥΑΚΗΣ ΤΟΠΟΘΕΣΙΑΣ",
        privacy_policy: "ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ",
    },	
    cs: {	
        title: "Informace o opravách pro odborné opravny",
        infoLine1: "Odborné opravny mohou požádat o přístup k informacím o opravách elektronických displejů v souladu s předpisy a podmínkami vymezenými v nařízení Komise (EU) 2019/2021 o ekodesignu elektronických displejů.",
        infoLine2: "Informace o opravách jednotlivých modelů displejů mohou být poskytnuty po uplynutí dvou let od uvedení prvního kusu daného modelu na trh.",
        infoLine3: "Pokud chcete o přístup k těmto informacím požádat, uveďte následující údaje:",
        modelName: "Název modelu",
        modelSelectionIntructions: "Uveďte název modelu",
        firstName: "Jméno",
        lastName: "Příjmení",
        email: "E-mail",
        companyName: "Název společnosti",
        vat: "Oficiální registrační číslo",
        vatInfo: "např. DIČ či registrační/certifikační číslo opravny",
        captchaInstructions: "Zadejte znaky, které jsou uvedeny na obrázku",
        validateCaptcha: "Ověřit",
        captchaNotOk: "Zadali jste chybnou hodnotu. Zkuste to znovu.",
        uploadFile: "Nahrát soubor",
        submitToSony: "Odeslat společnosti Sony",
        emptyModelError: "Vyberte model.",
        emptyLastNameError: "Uveďte své jméno.",
        emptyFirstNameError: "Uveďte své křestní jméno.",
        emptyEmailError: "Uveďte svou e-mailovou adresu.",
        invalidEmailError: "Uvedená e-mailová adresa je neplatná.",
        emptyCompanyNameError: "Uveďte název společnosti.",
        emptyVatError: "Uveďte oficiální registrační číslo.",
        uploadInfo: "Nahrajte doklad o pojištění odpovědnosti uvedené společnosti* (zásady, certifikát apod.)",
        fileUploadSizeError: "Tento soubor je příliš velký. Maximální povolená velikost je 10 MB.",
        fileUploadTypeError: "Tento typ souboru není podporován.",
        registrationCompleteInfoLine1: "Děkujeme za zaslání žádosti o přístup k informacím o opravě.",
        registrationCompleteInfoLine2: "Poskytnuté údaje aktuálně ověřujeme. Tato kontrola by měla být dokončena do 5 pracovních dnů.",
        generalErrorLine1: "Došlo k chybě",
        generalErrorLine2: "Zkuste to prosím později.",
        tac: "PODMÍNKY POUŽÍVÁNÍ WEBU",
        privacy_policy: "ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ",
    },	
    da: {	
        title: "Reparationsoplysninger til professionelle reparatører",
        infoLine1: "Professionelle reparatører kan anmode om adgang til oplysninger om reparation af elektroniske skærme i henhold til de forskrifter og betingelser, der er fastsat i forordningen om miljødesign (EU) 2019/2021.",
        infoLine2: "Der kan gives reparationsoplysninger for skærmmodeller efter to år fra deres første lancering på markedet.",
        infoLine3: "Hvis du vil anmode om adgang til reparationsoplysningerne, skal du angive følgende oplysninger:",
        modelName: "Modelnavn",
        modelSelectionIntructions: "Indtast et modelnavn",
        firstName: "Fornavn",
        lastName: "Efternavn",
        email: "E-mailadresse",
        companyName: "Virksomhedsnavn",
        vat: "Officielt registreringsnummer",
        vatInfo: "f.eks. momsnummer, registrerings-/certificeringsnummer som reparatør",
        captchaInstructions: "Indtast de tegn, du ser på billedet",
        validateCaptcha: "Godkend",
        captchaNotOk: "Du har indtastet en forkert værdi. Prøv igen.",
        uploadFile: "Upload fil",
        submitToSony: "Send til Sony",
        emptyModelError: "Vælg modellen.",
        emptyLastNameError: "Udfyld dit navn.",
        emptyFirstNameError: "Udfyld dit fornavn.",
        emptyEmailError: "Udfyld din e-mailadresse.",
        invalidEmailError: "Denne e-mailadresse er ugyldig.",
        emptyCompanyNameError: "Udfyld virksomhedsnavnet.",
        emptyVatError: "Udfyld dit officielle registreringsnummer.",
        uploadInfo: "Upload bevis for firmaets ansvarsforsikring* (politik, certifikat osv.)",
        fileUploadSizeError: "Den maksimale filstørrelse er 10 MB. Denne fil er for stor.",
        fileUploadTypeError: "Denne filtype understøttes ikke.",
        registrationCompleteInfoLine1: "Tak for din anmodning om adgang til reparationsoplysningerne.",
        registrationCompleteInfoLine2: "Vi er i øjeblikket ved at gennemgå de oplysninger, vi har givet, og vi bestræber os på at gennemføre denne gennemgang inden for 5 arbejdsdage.",
        generalErrorLine1: "Der er sket en fejl",
        generalErrorLine2: "Prøv igen senere.",
        tac: "VILKÅR FOR BRUG AF WEBSTEDET",
        privacy_policy: "BESKYTTELSE AF PERS. OPLYSN.",
    },	
    fi: {	
        title: "Korjaustietoa ammattilaisille",
        infoLine1: "Ammattimainen korjaushenkilöstö voi pyytää käyttöönsä elektronisten näyttöjen korjaustietoja ekologista suunnittelua koskevien vaatimusten mukaisesti (EU 2019/2021).",
        infoLine2: "Korjaustietoja voidaan tarjota näyttömalleille kaksi vuotta niiden julkistuksen jälkeen.",
        infoLine3: "Voit pyytää korjaustietoja antamalla seuraavat tiedot:",
        modelName: "Mallinimi",
        modelSelectionIntructions: "Kirjoita mallin nimi",
        firstName: "Etunimi",
        lastName: "Sukunimi",
        email: "Email Address",
        companyName: "Yrityksen nimi",
        vat: "Virallinen rekisteröintinumero",
        vatInfo: "esim. ALV-tunnus, korjaajan rekisteröinti-/sertifiointinumero",
        captchaInstructions: "Kirjoita kuvassa näkyvät merkit",
        validateCaptcha: "Vahvista",
        captchaNotOk: "Syötit virheellisen arvon. Yritä uudelleen.",
        uploadFile: "Lataa tiedosto palveluun",
        submitToSony: "Lähetä Sonylle",
        emptyModelError: "Valitse malli.",
        emptyLastNameError: "Kirjoita nimesi.",
        emptyFirstNameError: "Kirjoita etunimesi.",
        emptyEmailError: "Kirjoita sähköpostiosoitteesi.",
        invalidEmailError: "Sähköpostiosoite ei kelpaa.",
        emptyCompanyNameError: "Kirjoita yrityksen nimi.",
        emptyVatError: "Kirjoita virallinen rekisteröintinumero.",
        uploadInfo: "Lataa todiste yrityksen vastuuvakuutuksesta* (sopimus, todistus jne.)",
        fileUploadSizeError: "Tiedoston enimmäiskoko on 10 Mt. Tiedosto on liian suuri.",
        fileUploadTypeError: "Tiedostomuotoa ei tueta.",
        registrationCompleteInfoLine1: "Kiitos korjaustietojen käyttöpyynnöstäsi.",
        registrationCompleteInfoLine2: "Arvioimme antamasi tiedot ja pyrimme tekemään päätöksen viiden arkipäivän kuluessa.",
        generalErrorLine1: "Tapahtui virhe.",
        generalErrorLine2: "Yritä uudelleen myöhemmin.",
        tac: "SIVUSTON KÄYTTÖEHDOT",
        privacy_policy: "TIETOSUOJAKÄYTÄNTÖ",
    },	
    hu: {	
        title: "Javítási információk szakszervizek számára",
        infoLine1: "Az (EU) 2019/2021-es környezetbarát tervezési szabályozásban megfogalmazott előírások és feltételek alapján a szakszervizek hozzáférést kérhetnek az elektronikus kijelzők javítási információihoz.",
        infoLine2: "A kijelzőmodellek javítási információihoz a piacra kerülésüktől számított két évvel később juthatnak hozzá.",
        infoLine3: "A javítási információkhoz való hozzáférés igényléséhez a következő információkat szükséges megadni:",
        modelName: "Modellnév",
        modelSelectionIntructions: "Adja meg a modellnevet",
        firstName: "Utónév",
        lastName: "Vezetéknév",
        email: "E-mail-cím",
        companyName: "Cégnév",
        vat: "Hivatalos nyilvántartási szám",
        vatInfo: "pl. adószám, szerviz cégjegyzékszáma/nyilvántartási száma",
        captchaInstructions: "Írja be a képen látható karaktereket",
        validateCaptcha: "Érvényesítés",
        captchaNotOk: "Nem megfelelő értéket adott meg. Kérjük, próbálja újra.",
        uploadFile: "Fájl feltöltése",
        submitToSony: "Küldés a Sony-hoz",
        emptyModelError: "Válasszon modellt.",
        emptyLastNameError: "Írja be a nevét.",
        emptyFirstNameError: "Írja be az utónevét.",
        emptyEmailError: "Írja be az e-mail-címét.",
        invalidEmailError: "Az e-mail-cím érvénytelen.",
        emptyCompanyNameError: "Írja be a cégnevet.",
        emptyVatError: "Írja be a hivatalos nyilvántartási számot.",
        uploadInfo: "Kérjük, töltse fel a cég felelősségbiztosításáról szóló nyomtatványt* (nyilatkozat, tanúsítvány, stb)",
        fileUploadSizeError: "A fájl mérete legfeljebb 10 MB lehet.",
        fileUploadTypeError: "Ez a fájltípus nem támogatott.",
        registrationCompleteInfoLine1: "Köszönjük javítási információkra vonatkozó hozzáférési igénylését!",
        registrationCompleteInfoLine2: "Jelenleg a megadott információkat ellenőrizzük. Az ellenőrzés várhatóan 5 munkanapon belül lezárul.",
        generalErrorLine1: "Hiba történt",
        generalErrorLine2: "Kérjük, próbálja meg később.",
        tac: "WEBHELYHASZNÁLATI FELTÉTELEK",
        privacy_policy: "ADATBIZTONSÁG",
    },	
    it: {	
        title: "Informazioni sulla riparazione per tecnici professionisti",
        infoLine1: "I tecnici professionisti possono richiedere l'accesso alle informazioni di riparazione dei display elettronici, in base alle prescrizioni e alle condizioni stabilite nella normativa sulla progettazione ecologica (EU) 2019/2021.",
        infoLine2: "Le informazioni sulla riparazione possono essere fornite per i modelli con display dopo un periodo di due anni dalla loro prima introduzione sul mercato.",
        infoLine3: "Per richiedere l'accesso alle informazioni sulla riparazione, fornire le seguenti informazioni:",
        modelName: "Nome modello",
        modelSelectionIntructions: "Digitare un nome modello",
        firstName: "Nome",
        lastName: "Cognome",
        email: "Indirizzo e-mail",
        companyName: "Nome dell'azienda",
        vat: "Numero di registrazione ufficiale",
        vatInfo: "ad es., numero di partita IVA, numero di registrazione/certificazione come tecnico riparatore",
        captchaInstructions: "Digita i caratteri che vedi nell'immagine",
        validateCaptcha: "Convalida",
        captchaNotOk: "È stato immesso un valore errato. Riprovare.",
        uploadFile: "Carica file",
        submitToSony: "Invia a Sony",
        emptyModelError: "Selezionare il modello.",
        emptyLastNameError: "Inserire nome e cognome.",
        emptyFirstNameError: "Inserire il nome.",
        emptyEmailError: "Inserire l'indirizzo e-mail.",
        invalidEmailError: "Questo indirizzo e-mail non è valido.",
        emptyCompanyNameError: "Compilare il campo del nome dell'azienda.",
        emptyVatError: "Inserire il numero di registrazione ufficiale.",
        uploadInfo: "Caricare la prova dell'assicurazione di responsabilità civile della società* (polizza, certificato, ecc.)",
        fileUploadSizeError: "La dimensione massima del file è di 10 MB. Questo file è troppo grande.",
        fileUploadTypeError: "Questo tipo di file non è supportato.",
        registrationCompleteInfoLine1: "Grazie per aver richiesto di accedere alla piattaforma di riparazione.",
        registrationCompleteInfoLine2: "Stiamo esaminando le informazioni fornite e intendiamo completare la verifica entro 5 giorni lavorativi.",
        generalErrorLine1: "Si è verificato un errore",
        generalErrorLine2: "Al momento non è possibile accedere ai titoli selezionati. Riprovare più tardi.",
        tac: "TERMINI/CONDIZIONI SITO WEB",
        privacy_policy: "INFORMATIVA SULLA PRIVACY",
    },	
    lt: {	
        title: "Meistrams skirta remonto darbų informacija",
        infoLine1: "Remontuojant elektroninius ekranus, meistrai gali prašyti prieigos prie remonto darbų informacijos, kaip tai numatyta ekologinio projektavimo reglamento (ES) 2019/2021 nurodymuose ir sąlygose.",
        infoLine2: "Ekranų modelių remonto darbų informacija gali būti pateikta praėjus dvejiems metams nuo jų pirmo pristatymo rinkoje.",
        infoLine3: "Norėdami prašyti prieigos prie remonto darbų informacijos, pateikite toliau nurodytą informaciją:",
        modelName: "Modelio pavadinimas",
        modelSelectionIntructions: "Įveskite modelio pavadinimą",
        firstName: "Vardas",
        lastName: "Pavardė",
        email: "Email Address",
        companyName: "Bendrovės pavadinimas",
        vat: "Oficialus registracijos Nr.",
        vatInfo: "pvz., PVM mokėtojo kodas, meistro registracijos / patvirtinimo Nr.",
        captchaInstructions: "Įveskite paveikslėlyje matomus simbolius",
        validateCaptcha: "Tikrinti",
        captchaNotOk: "Įvesta netinkama reikšmė. Bandykite dar kartą.",
        uploadFile: "Įkelti failą",
        submitToSony: "Pateikti „Sony“",
        emptyModelError: "Pasirinkite modelį.",
        emptyLastNameError: "Įveskite savo pavardę.",
        emptyFirstNameError: "Įveskite savo vardą.",
        emptyEmailError: "Įveskite savo el. pašto adresą.",
        invalidEmailError: "Šis el. pašto adresas yra netinkamas.",
        emptyCompanyNameError: "Įveskite bendrovės pavadinimą.",
        emptyVatError: "Įveskite oficialų registracijos Nr.",
        uploadInfo: "Įkelkite bendrovės atsakomybės draudimo įrodymą * (polisą, sertifikatą ir pan.)",
        fileUploadSizeError: "Maks.failo dydis 10 MB. Šis failas yra per didelis.",
        fileUploadTypeError: "Šis failo tipas nepalaikomas.",
        registrationCompleteInfoLine1: "Dėkojame, kad kreipėtės dėl prieigos prie remonto darbų informacijos.",
        registrationCompleteInfoLine2: "Šiuo metu peržiūrime pateiktą informaciją ir pasistengsime užbaigti šią peržiūrą per 5 darbo dienas.",
        generalErrorLine1: "Įvyko klaida",
        generalErrorLine2: "Bandykite dar kartą vėliau.",
        tac: "SVETAINĖS TAISYKLĖS IR SĄLYGOS",
        privacy_policy: "PRIVATUMO POLITIKA",
    },	
    pl: {	
        title: "Informacje dotyczące napraw dla profesjonalnych serwisów naprawczych",
        infoLine1: "Profesjonalne serwisy naprawcze mają prawo dostępu do informacji dotyczących napraw wyświetlaczy elektronicznych zgodnie z postanowieniami i warunkami zawartymi w rozporządzeniu dotyczącym ekoprojektu (UE) 2019/2021.",
        infoLine2: "Informacje dotyczące napraw modeli wyświetlaczy mogą zostać przekazane po upływie dwóch lat od wprowadzenia do obrotu pierwszego egzemplarza.",
        infoLine3: "Występując o dostęp do informacji dotyczących napraw, należy przekazać następujące informacje:",
        modelName: "Nazwa modelu",
        modelSelectionIntructions: "Podaj nazwę modelu",
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "E-mail",
        companyName: "Nazwa firmy",
        vat: "Urzędowy numer rejestracyjny",
        vatInfo: "np. NIP, numer rejestracyjny/certyfikacyjny serwisu naprawczego",
        captchaInstructions: "Wpisz znaki widoczne na ilustracji",
        validateCaptcha: "Zatwierdź",
        captchaNotOk: "Nieprawidłowa wartość. Spróbuj ponownie.",
        uploadFile: "Prześlij plik",
        submitToSony: "Wyślij do Sony",
        emptyModelError: "Wybierz model.",
        emptyLastNameError: "Podaj nazwisko.",
        emptyFirstNameError: "Podaj imię",
        emptyEmailError: "Podaj e-mail.",
        invalidEmailError: "Nieprawidłowy e-mail.",
        emptyCompanyNameError: "Podaj nazwę firmy.",
        emptyVatError: "Podaj urzędowy numer rejestracyjny.",
        uploadInfo: "Prześlij potwierdzenie ubezpieczenia firmy od odpowiedzialności cywilnej* (polisa, certyfikat itp.)",
        fileUploadSizeError: "Maksymalny rozmiar pliku to 10 MB. Ten plik jest za duży.",
        fileUploadTypeError: "Ten rodzaj pliku nie jest obsługiwany.",
        registrationCompleteInfoLine1: "Dziękujemy za zgłoszenie w sprawie dostępu do informacji dotyczących napraw.",
        registrationCompleteInfoLine2: "Obecnie weryfikujemy przesłane informacje. Weryfikacja nie powinna trwać dłużej niż 5 dni roboczych.",
        generalErrorLine1: "Wystąpił błąd",
        generalErrorLine2: "Spróbuj ponownie później.",
        tac: "REGULAMIN WITRYNY INTERNETOWEJ",
        privacy_policy: "OCHRONA PRYWATNOŚCI",
    },	
    pt: {	
        title: "Informações de reparação para técnicos de reparação",
        infoLine1: "Os técnicos de reparação podem solicitar acesso às informações de reparação de visores eletrónicos, de acordo com as prescrições e condições estabelecidas no regulamento de design ecológico (UE) 2019/2021.",
        infoLine2: "As informações de reparação de modelos de visores podem ser fornecidas dois anos após a sua primeira introdução no mercado.",
        infoLine3: "Para solicitar acesso às informações de reparação, forneça as seguintes informações:",
        modelName: "Nome do modelo",
        modelSelectionIntructions: "Escreva o nome do modelo",
        firstName: "Nome próprio",
        lastName: "Apelido",
        email: "Email Address",
        companyName: "Nome da empresa",
        vat: "Número de registo oficial",
        vatInfo: "por exemplo, número de identificação fiscal, número de registo/certificação que comprova o exercício da atividade de técnico de reparação",
        captchaInstructions: "Escreva os caracteres que vê na imagem",
        validateCaptcha: "Validar",
        captchaNotOk: "Introduziu um valor errado. Tente novamente.",
        uploadFile: "Carregar ficheiro",
        submitToSony: "Enviar para a Sony",
        emptyModelError: "Selecione o modelo.",
        emptyLastNameError: "Escreva o seu nome.",
        emptyFirstNameError: "Escreva o seu nome próprio.",
        emptyEmailError: "Introduza o seu endereço de e-mail.",
        invalidEmailError: "Este endereço de e-mail é inválido.",
        emptyCompanyNameError: "Escreva o nome da empresa.",
        emptyVatError: "Introduza o seu número de registo oficial.",
        uploadInfo: "Carregue um documento comprovativo do seguro de responsabilidade da empresa* (apólice, certificado, etc.)",
        fileUploadSizeError: "O tamanho máximo de ficheiro suportado é de 10 MB. Este ficheiro é demasiado grande.",
        fileUploadTypeError: "Este tipo de ficheiro não é suportado.",
        registrationCompleteInfoLine1: "Obrigado pelo seu pedido de acesso às informações de reparação.",
        registrationCompleteInfoLine2: "Estamos a rever as informações fornecidas e pretendemos concluir esta revisão no prazo de 5 dias úteis.",
        generalErrorLine1: "Ocorreu um erro",
        generalErrorLine2: "Tente novamente mais tarde.",
        tac: "TERMOS E CONDIÇÕES DO SITE",
        privacy_policy: "DECLARAÇÃO DE PRIVACIDADE",
    },	
    ro: {	
        title: "Informaţii privind reparaţiile pentru personalul de service profesionist",
        infoLine1: "Personalul de service profesionist poate solicita accesul la informaţiile privind repararea afişajelor electronice în conformitate cu prescripţiile şi condiţiile stabilite în regulamentul de design ecologic (UE) 2019/2021.",
        infoLine2: "Informaţiile privind reparaţiile pot fi furnizate pentru modelele de afişaj după o perioadă de doi ani de la prima lor introducere pe piaţă.",
        infoLine3: "Pentru a solicita accesul la informaţii privind reparaţiile, furnizaţi următoarele informaţii:",
        modelName: "Nume model",
        modelSelectionIntructions: "Tastaţi un nume de model",
        firstName: "Prenume",
        lastName: "Nume de familie",
        email: "Adresă de e-mail",
        companyName: "Nume companie",
        vat: "Cod de înregistrare fiscală",
        vatInfo: "de ex., cod TVA, număr de înregistrare/certificare ca reparator",
        captchaInstructions: "Introduceţi caracterele pe care le vedeţi în imagine",
        validateCaptcha: "Validare",
        captchaNotOk: "Aţi introdus o valoare greşită. Încercaţi din nou.",
        uploadFile: "Încărcaţi fişierul",
        submitToSony: "Transmiteţi către Sony",
        emptyModelError: "Selectaţi modelul.",
        emptyLastNameError: "Completaţi numele dvs. de familie.",
        emptyFirstNameError: "Completaţi prenumele dvs.",
        emptyEmailError: "Completaţi adresa dvs. de e-mail.",
        invalidEmailError: "Această adresă de e-mail nu este validă.",
        emptyCompanyNameError: "Completaţi numele companiei.",
        emptyVatError: "Completaţi codul de înregistrare fiscală.",
        uploadInfo: "Încărcaţi dovezi ale asigurării de răspundere civilă a companiei* (poliţă, certificat etc.)",
        fileUploadSizeError: "Dimensiunea maximă a fişierului este de 10 MB. Acest fişier este prea mare.",
        fileUploadTypeError: "Acest tip de fişier nu este acceptat.",
        registrationCompleteInfoLine1: "Vă mulţumim pentru solicitarea dvs. de acces la informaţiile privind reparaţiile.",
        registrationCompleteInfoLine2: "În prezent, analizăm informaţiile furnizate şi ne propunem să finalizăm această analiză în termen de 5 zile lucrătoare.",
        generalErrorLine1: "A intervenit o eroare",
        generalErrorLine2: "Încercaţi din nou mai târziu.",
        tac: "CONDIŢII DE UTILIZARE SITE",
        privacy_policy: "POLITICĂ DE CONFIDENŢIALITATE",
    },	
    sk: {	
        title: "Informácie o opravách pre profesionálnych opravárov",
        infoLine1: "Profesionálni opravári môžu požiadať o prístup k informáciám o opravách elektronických displejov v súlade s predpismi a podmienkami stanovenými v nariadení o ekodizajne (EÚ) 2019/2021.",
        infoLine2: "Informácie o oprave môžu byť poskytnuté pre modely displejov po uplynutí dvoch rokov od ich prvého uvedenia na trh.",
        infoLine3: "Ak chcete požiadať o prístup k informáciám o oprave, uveďte nasledujúce informácie:",
        modelName: "Názov modelu",
        modelSelectionIntructions: "Zadajte názov modelu",
        firstName: "Meno",
        lastName: "Priezvisko",
        email: "E-mailová adresa",
        companyName: "Názov spoločnosti",
        vat: "Úradné registračné číslo",
        vatInfo: "napr. DIČ, číslo registrácie/certifikácie opravára",
        captchaInstructions: "Zadajte znaky, ktoré vidíte na obrázku",
        validateCaptcha: "Overiť",
        captchaNotOk: "Zadali ste nesprávnu hodnotu. Prosím skúste znova.",
        uploadFile: "Nahrajte súbor",
        submitToSony: "Odoslať spoločnosti Sony",
        emptyModelError: "Vyberte model.",
        emptyLastNameError: "Vyplňte svoje meno.",
        emptyFirstNameError: "Vyplňte svoje krstné meno.",
        emptyEmailError: "Vyplňte svoju e-mailovú adresu.",
        invalidEmailError: "Táto e-mailová adresa je neplatná.",
        emptyCompanyNameError: "Vyplňte názov spoločnosti.",
        emptyVatError: "Vyplňte úradné registračné číslo.",
        uploadInfo: "Nahrajte doklad o poistení zodpovednosti za škodu vzťahujúci sa na vašu spoločnosť* (poistná zmluva, certifikát a pod.)",
        fileUploadSizeError: "Maximálna veľkosť súboru je 10 MB. Tento súbor je príliš veľký.",
        fileUploadTypeError: "Tento typ súboru nie je podporovaný.",
        registrationCompleteInfoLine1: "Ďakujeme za vašu žiadosť o prístup k informáciám o oprave.",
        registrationCompleteInfoLine2: "Momentálne posudzujeme poskytnuté informácie a kontrolu by sme mali dokončiť do 5 pracovných dní.",
        generalErrorLine1: "Došlo k chybe.",
        generalErrorLine2: "Skúste to znova neskôr.",
        tac: "PODMIENKY POUŽÍVANIA LOKALITY",
        privacy_policy: "ZÁSADY OCHRANY SÚKROMIA",
    },	
    si: {	
        title: "Navodila za popravilo za usposobljeno servisno osebje",
        infoLine1: "Usposobljeno servisno osebje lahko zahteva dostop do navodil za popravilo elektronskih zaslonov v skladu s predpisi in pogoji, ki jih določa uredba za okoljsko primerno zasnovo (EU) 2019/2021.",
        infoLine2: "Navodila za popravilo za modele zaslonov lahko posredujemo dve leti po prvi pojavi modela na trgu.",
        infoLine3: "Za zahtevo po navodilih za popravilo nam posredujte te informacije:",
        modelName: "Ime modela",
        modelSelectionIntructions: "Vpišite ime modela",
        firstName: "Ime",
        lastName: "Priimek",
        email: "E-poštni naslov",
        companyName: "Ime podjetja",
        vat: "Uradna registracijska številka",
        vatInfo: "npr.: številka za DDV, registracijska/certifikacijska številka za serviserja",
        captchaInstructions: "Vnesite znake, ki jih vidite na sliki",
        validateCaptcha: "Potrdite",
        captchaNotOk: "Vnesli ste napačno vrednost. Poskusite znova.",
        uploadFile: "Nalaganje datotek",
        submitToSony: "Pošlji družbi Sony",
        emptyModelError: "Izberite model.",
        emptyLastNameError: "Vnesite svoje ime.",
        emptyFirstNameError: "Vnesite svoje ime.",
        emptyEmailError: "Vnesite svoj e-poštni naslov.",
        invalidEmailError: "E-poštni naslov ni veljaven.",
        emptyCompanyNameError: "Vnesite ime podjetja.",
        emptyVatError: "Vnesite uradno registracijsko številko.",
        uploadInfo: "Naložite dokazilo o zavarovanju odgovornosti podjetja* (pravilnik, certifikat itd.).",
        fileUploadSizeError: "Največja velikost datoteke je 10 MB. Datoteka je prevelika.",
        fileUploadTypeError: "Ta vrsta datoteke ni podprta.",
        registrationCompleteInfoLine1: "Hvala za povpraševanje po navodilih za popravilo.",
        registrationCompleteInfoLine2: "Trenutno obdelujemo informacije, ki ste nam jih posredovali. Predvidoma bo ta pregled trajal 5 delovnih dni.",
        generalErrorLine1: "Prišlo je do napake.",
        generalErrorLine2: "Poskusite znova pozneje.",
        tac: "POGOJI ZA UPORABO SPL. MESTA",
        privacy_policy: "PRAVILNIK O ZASEBNOSTI",
    },	
    es: {	
        title: "Información sobre reparaciones para reparadores profesionales",
        infoLine1: "Los reparadores profesionales pueden solicitar el acceso a la información sobre reparaciones de pantallas electrónicas, conforme a las disposiciones y condiciones establecidas en el reglamento (UE) 2019/2021 sobre diseño ecológico.",
        infoLine2: "La información sobre reparaciones de los modelos de pantalla se podrá proporcionar tras un periodo de dos años desde su lanzamiento al mercado.",
        infoLine3: "Para solicitar acceso a la información sobre reparaciones, proporciona la siguiente información:",
        modelName: "Nombre de modelo",
        modelSelectionIntructions: "Introduce un nombre de modelo",
        firstName: "Nombre",
        lastName: "Apellidos",
        email: "Dirección de correo electrónico",
        companyName: "Nombre de tu empresa",
        vat: "Número de registro oficial",
        vatInfo: "p. ej., el número de identificación fiscal, el número de registro/certificación como reparador",
        captchaInstructions: "Introduce los caracteres que veas en la imagen",
        validateCaptcha: "Validar",
        captchaNotOk: "Has introducido un valor incorrecto. Inténtalo de nuevo.",
        uploadFile: "Cargar archivo",
        submitToSony: "Enviar a Sony",
        emptyModelError: "Selecciona el modelo.",
        emptyLastNameError: "Rellena el nombre.",
        emptyFirstNameError: "Rellena el nombre.",
        emptyEmailError: "Rellena la dirección de correo electrónico.",
        invalidEmailError: "La dirección de correo electrónico no es válida.",
        emptyCompanyNameError: "Rellena el nombre de la empresa.",
        emptyVatError: "Rellena el número de registro oficial.",
        uploadInfo: "Carga algún documento del seguro de responsabilidad civil de la empresa* (la póliza, el certificado, etc.).",
        fileUploadSizeError: "El límite de tamaño del archivo es de 10 MB. Este archivo es demasiado grande.",
        fileUploadTypeError: "No se admite este tipo de archivo.",
        registrationCompleteInfoLine1: "Gracias por tu solicitud para acceder a la información sobre reparaciones.",
        registrationCompleteInfoLine2: "Estamos revisando la información proporcionada y esperamos haber terminado en un plazo de 5 días laborables.",
        generalErrorLine1: "Se ha producido un error",
        generalErrorLine2: "Inténtalo de nuevo más tarde.",
        tac: "TÉRMINOS/CONDICIONES USO WEB",
        privacy_policy: "POLÍTICA DE PRIVACIDAD",
    },	
    sv: {	
        title: "Reparationsinformation för professionella reparatörer",
        infoLine1: "Professionella reparatörer kan begära tillgång till reparationsinformation om elektroniska skärmar i enlighet med de föreskrifter och villkor som fastställts i förordningen om ekodesign (EU) 2019/2021.",
        infoLine2: "Reparationsinformation kan tillhandahållas för skärmmodeller två år efter det att de introducerades på marknaden för första gången.",
        infoLine3: "Om du vill ha tillgång till reparationsinformationen ska du ange följande information:",
        modelName: "Modellnamn",
        modelSelectionIntructions: "Ange ett modellnamn",
        firstName: "Förnamn",
        lastName: "Efternamn",
        email: "E-postadress",
        companyName: "Företagsnamn",
        vat: "Officiellt registreringsnummer",
        vatInfo: "t.ex. momsregistreringsnummer, registrerings-/certifieringsnummer som reparatör",
        captchaInstructions: "Skriv in de tecken du ser på bilden",
        validateCaptcha: "Verifiera",
        captchaNotOk: "Du angav ett felaktigt värde. Försök igen.",
        uploadFile: "Överför fil",
        submitToSony: "Skicka till Sony",
        emptyModelError: "Välj modell.",
        emptyLastNameError: "Fyll i ditt efternamn.",
        emptyFirstNameError: "Fyll i ditt förnamn.",
        emptyEmailError: "Fyll i din e-postadress.",
        invalidEmailError: "E-postadressen är ogiltig.",
        emptyCompanyNameError: "Fyll i företagsnamn.",
        emptyVatError: "Fyll i officiellt registreringsnummer.",
        uploadInfo: "Ladda upp bevis på företagets ansvarsförsäkring* (försäkringsbrev, certifikat osv.)",
        fileUploadSizeError: "Maximal filstorlek är 10 MB. Filen är för stor.",
        fileUploadTypeError: "Den här filtypen stöds inte.",
        registrationCompleteInfoLine1: "Tack för din ansökan om åtkomst till reparationsinformationen.",
        registrationCompleteInfoLine2: "Vi granskar för närvarande de uppgifter som du tillhandahållit och siktar på att ha slutfört granskningen inom 5 arbetsdagar.",
        generalErrorLine1: "Ett fel har inträffat",
        generalErrorLine2: "Försök igen senare.",
        tac: "VILLKOR FÖR WEBBPLATSEN",
        privacy_policy: "SEKRETESSPOLICY",
    },	
    no: {	
        title: "Reparasjonsinformasjon for profesjonelle reparatører",
        infoLine1: "Profesjonelle reparatører kan be om tilgang til reparasjonsinformasjon om elektroniske skjermer i henhold til forskriftene og betingelsene som er angitt i ecodesign-direktivet (EU) 2019/2021.",
        infoLine2: "Reparasjonsinformasjon kan gis for skjermmodeller etter en periode på to år fra de først ble lansert på markedet.",
        infoLine3: "Oppgi følgende opplysninger for å få tilgang til reparasjonsinformasjonen:",
        modelName: "Modellnavn",
        modelSelectionIntructions: "Angi et modellnavn",
        firstName: "Fornavn",
        lastName: "Etternavn",
        email: "E-postadresse",
        companyName: "Firmanavn",
        vat: "Offisielt registreringsnummer",
        vatInfo: "for eksempel mva.-nummer, registrerings-/sertifiseringsnummer som reparatør",
        captchaInstructions: "Skriv inn tegnene du ser i bildet",
        validateCaptcha: "Valider",
        captchaNotOk: "Du har angitt feil verdi. Prøv på nytt.",
        uploadFile: "Last opp fil",
        submitToSony: "Send inn til Sony",
        emptyModelError: "Velg modellen.",
        emptyLastNameError: "Fyll ut navnet ditt.",
        emptyFirstNameError: "Fyll ut fornavnet ditt.",
        emptyEmailError: "Fyll ut e-postadressen din.",
        invalidEmailError: "Denne e-postadressen er ugyldig.",
        emptyCompanyNameError: "Fyll ut firmanavnet.",
        emptyVatError: "Fyll ut det offisielle registreringsnummeret.",
        uploadInfo: "Last opp bevis på firmaets ansvarsforsikring* (retningslinjer, sertifikat osv.)",
        fileUploadSizeError: "Den maksimale filstørrelsen er 10 MB. Denne filen er for stor.",
        fileUploadTypeError: "Denne filtypen støttes ikke.",
        registrationCompleteInfoLine1: "Takk for forespørselen din om tilgang til reparasjonsinformasjon.",
        registrationCompleteInfoLine2: "Vi går for øyeblikket gjennom de oppgitte opplysningene, og vi tar sikte på å fullføre denne gjennomgangen innen fem virkedager.",
        generalErrorLine1: "Det oppstod en feil",
        generalErrorLine2: "Prøv igjen senere.",
        tac: "VILKÅR FOR BRUK AV NETTSTED",
        privacy_policy: "PERSONVERNERKLÆRING",
    },	
    tr: {	
        title: "Onarım uzmanlarına yönelik onarım bilgisi",
        infoLine1: "Avrupa Birliği'nin 2019/2021 tarihli ekolojik tasarım yönetmeliğinde belirtilen yönergeler ve koşullar uyarınca onarım uzmanları, elektronik ekranlarla ilgili onarım bilgilerine erişim talep edebilir.",
        infoLine2: "Ekran modelleriyle ilgili onarım bilgileri, söz konusu modellerin piyasaya ilk sürüldüğü tarihten itibaren iki yıl sonra sağlanabilir.",
        infoLine3: "Onarım bilgisine erişim talebi iletmek için lütfen şu bilgileri paylaşın:",
        modelName: "Model Adı",
        modelSelectionIntructions: "Model adı girin",
        firstName: "Adı",
        lastName: "Soyadı",
        email: "Email Address",
        companyName: "Şirket Adı",
        vat: "Resmi kayıt numarası",
        vatInfo: "ör. tamirci olarak sahip olduğunuz KDV numarası, kayıt veya sertifika numarası",
        captchaInstructions: "Resimde gördüğünüz karakterleri yazın",
        validateCaptcha: "Onayla",
        captchaNotOk: "Yanlış bir değer girdiniz. Lütfen tekrar deneyin.",
        uploadFile: "Dosya yükle",
        submitToSony: "Sony'ye gönder",
        emptyModelError: "Lütfen modeli seçin.",
        emptyLastNameError: "Lütfen tam adınızı girin.",
        emptyFirstNameError: "Lütfen adınızı girin.",
        emptyEmailError: "Lütfen e-posta adresinizi girin.",
        invalidEmailError: "Girdiğiniz e-posta adresi geçersiz.",
        emptyCompanyNameError: "Lütfen Şirket Adı girin.",
        emptyVatError: "Lütfen resmi kayıt numaranızı girin.",
        uploadInfo: "Şirketin sağlamış olduğu sorumluluk garantisini* (ör. politika, sertifika) yükleyin.",
        fileUploadSizeError: "Maksimum dosya boyutu 10 MB'tır. Yüklediğiniz dosya bu sınırı aşıyor.",
        fileUploadTypeError: "Bu dosya türü desteklenmiyor.",
        registrationCompleteInfoLine1: "Onarım bilgilerine erişme talebiniz için teşekkür ederiz.",
        registrationCompleteInfoLine2: "Paylaştığınız bilgiler şu anda inceleme aşamasında. İnceleme işlemini 5 iş günü içinde tamamlamaya çalışacağız.",
        generalErrorLine1: "Bir hata oluştu",
        generalErrorLine2: "Lütfen daha sonra tekrar deneyin.",
        tac: "WEB SİTESİ KULLANIM ŞARTLARI",
        privacy_policy: "KİŞİSEL VERİLERİN KORUNMASI VE GİZLİLİK POLİTİKASI",
    },	
};



 const translate = (lang, item) => {
   let all = translations[lang];
   if (!all) all = translations["en"];
   let translated = all[item];
   if (!translated && lang !== "en") translated = translations["en"][item];
   if (!translated) translated = item;
   return translated;
}

export default translate;